import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IntakeService } from '../services/intake.service';
import { Observable, of, switchMap, take, tap } from 'rxjs';
import { ApplicationAnswers } from '../models/data.model';
import { SadaCustomValidator } from '../validator/sada-custom-validator';
import { SharedUtil } from '@shared/shared.util';
import { PageInfo } from '../models/page-map';
import { MccssAddressData, MccssAddressSearchStore } from '@mccss/pclookup-common-ui';
import { AddressHelper, mailingAddressFields, trusteeAddressFields } from '../utils/address-helper';

@Injectable({
  providedIn: 'root'
})
export class AppDataResolver {

  constructor(
    private readonly intakeService: IntakeService,
    private readonly addressStore: MccssAddressSearchStore,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApplicationAnswers> {
    const isResume = route.queryParams?.action === 'resume';
    const useCache = route.queryParamMap.get('useCache') === 'true';
    if (isResume) {
      return this.intakeService.getSavedApplication().pipe(
        tap(appAnswers => this.setIntakeFlags(appAnswers)),
        switchMap(appAnswers => {
          if (this.isAddressPage(state)) {
            return this.addressStore.getEnteredAddress$.pipe(
              take(1), // Take the latest value and complete
              switchMap((mccssAddressData: MccssAddressData | null) => {
                return this.processAddress(appAnswers, useCache, mccssAddressData);
              })
            );
          }
          return of(appAnswers);
        })
      );
    } else {
      return this.intakeService.getApplication(route.data.pageId).pipe(
        switchMap(appAnswers => {
          if (this.isAddressPage(state)) {
            return this.addressStore.getEnteredAddress$.pipe(
              take(1), // Take the latest value and complete
              switchMap((mccssAddressData: MccssAddressData | null) => {
                return this.processAddress(appAnswers, useCache, mccssAddressData);
              })
            );
          }
          return of(appAnswers);
        })
      );
    }
  }

  /**
   * Sets various flags in the IntakeService based on the application answers data.
   */
  private setIntakeFlags(data: ApplicationAnswers): void {
    const applicantSponsored = data?.jsonData?.applicantSponsored;
    const spouseSponsored = data?.jsonData?.spouseSponsored;
    const childList = data?.jsonData?.childList;
    const maritalStatus = data?.jsonData?.maritalStatus;
    const childrenLivingWithYou = data?.jsonData?.childrenLivingWithYou;
    const shouldExcludeDbd = SharedUtil.checkIfDbdShouldBeExcluded(data);

    if (applicantSponsored === 'yes' || SadaCustomValidator.isFamilySponsored([spouseSponsored, childList])) {
      this.intakeService.setSponsorshipRequired(true);
    }

    if (maritalStatus === 'Married' || maritalStatus === 'Common Law') {
      this.intakeService.setAppSpouseRequired(true);
    }

    if (childrenLivingWithYou === 'yes') {
      this.intakeService.setAppChildRequired(true);
    }

    if (shouldExcludeDbd) {
      this.intakeService.setShouldExcludeDbd(true);
    }
  }

  /** Check if the current page is one of the address pages */
  private isAddressPage(state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    const targetPages = [PageInfo.addressInfo, PageInfo.addressReview, PageInfo.confirmAddress];
    return targetPages.some(page => url.includes(page));
  }

  /**
   * If url parameter 'useCache' is true, build address data from applicationAnswers and cache it;
   * If useCache is NOT true and there is address data in the cache, update it based on weather
   * the user switches among mailing, trustee individual and organization
   */
  private processAddress(appAnswers: ApplicationAnswers | null, useCache: boolean, 
                         addressData: MccssAddressData | null): Observable<ApplicationAnswers | null> {
     if (this.updateAddressCache(appAnswers, addressData) || !useCache) {
        // Build and cache address data from applicationAnswers
        const address = AddressHelper.buildAddressFromAnswers(appAnswers);
        this.addressStore.setEnteredAddress(address);
    }

    return of(appAnswers);
  }

  /** 
   * When switches among mailing, trustee individual and organization, update the address cache 
   * This is to support browser back button when switch
  */
  private updateAddressCache(appAnswers: ApplicationAnswers | null, addressData: MccssAddressData | null) : boolean {
    if (AddressHelper.isTrusteeAddressEnabled(appAnswers?.jsonData)) {
      if (addressData?.isMailingAddressEnabled) {   // Switch from Mailing ====> Trustee
        AddressHelper.removeFields(appAnswers, mailingAddressFields)
        return true;
      }
      // Switch between Trustee Individual and Trustee Organization
      if (AddressHelper.isOrganizationTrustee(appAnswers?.jsonData) && !addressData?.isOrganizationTrustee
        || !AddressHelper.isOrganizationTrustee(appAnswers?.jsonData) && addressData?.isOrganizationTrustee) {

        AddressHelper.removeFields(appAnswers, trusteeAddressFields)
        return true;
      }
    } else if (addressData?.isTrusteeAddressEnabled) {  // Switch from Trustee ====> Mailing
      AddressHelper.removeFields(appAnswers, trusteeAddressFields)
      return true;
    }

    return false;
  }
}
