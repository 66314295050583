<sd-page-container [pageId]="pageId">
  <div id="page-alerts-container">
    <div *ngIf="!isErrorPage" class="ontario-alert ontario-alert--success">
      <div class="ontario-alert__header">
        <div class="ontario-alert__header-icon">
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use href="#ontario-icon-alert-success"></use>
          </svg>
        </div>
        <h2 id="success-title" class="ontario-alert__header-title ontario-h4">{{'confirmation.success.title' | translate}}</h2>
      </div>
      <div class="ontario-alert__body">
        <p>{{'confirmation.doNotReapplyText' | translate}}</p>
      </div>
    </div>
    <sd-info-text *ngIf="caringForChild" header="confirmation.tcaNote"
                  contentId="tcaMessageInfo"
                  content="confirmation.tcaMessage"
                  [link]="tcaLink"
                  linkId="tca-Link"
                  linkLabel="confirmation.tcaLink">
    </sd-info-text>
    <sd-info-text *ngIf="acsdEligibility" header="confirmation.tcaNote"
                  contentId="acsdMessageInfo"
                  content="confirmation.acsdMessage"
                  [link]="acsdLink"
                  linkId="acsd-Link"
                  linkLabel="confirmation.acsdLink">
    </sd-info-text>
  </div>

  <div id="ref-code" data-e2e="confirmationId" class="ontario-h4 ontario-margin-top-40-! alert-nomaxwidth">{{'confirmation.applicationReferenceNumber' | translate}}{{referenceCode}}</div>
  <p *ngIf="isErrorPage" class="ontario-margin-top-40-!">{{'confirmation.thanksTitle' | translate}}</p>

  <div id="next-steps-title" class="ontario-h5 ontario-margin-top-40-!" >{{'confirmation.nextSteps' | translate}}</div>
  <ul id="next-steps" class="ontario-margin-left-32-!" type="1">
    <li id="next-steps-item1">{{ 'confirmation.step1' | translate}}</li>
    <li id="next-steps-item2"><span [innerHTML]="'confirmation.step2' | translate"></span></li>
    <li *ngIf="applicationProgramType === ProgramType.ONW" id="next-steps-onw-item3"><span [innerHTML]="'confirmation.step3.onw' | translate: employmentCounsellorLinkParam"></span></li>
  </ul>

  <div *ngIf="applicationProgramType === ProgramType.ONW">
    <div id="onw-title" class="ontario-margin-top-40-! ontario-padding-bottom-8-!" ><span [innerHTML]="'confirmation.onwNextSteps' | translate"></span></div>
    <ul id="onw-steps" class="ontario-margin-left-32-!" type="1">
      <li id="onw-steps-item1"><span [innerHTML]="'confirmation.onwStep1' | translate"></span></li>
      <li id="onw-steps-item2"><span [innerHTML]="'confirmation.onwStep2' | translate"></span></li>
      <li id="onw-steps-item3"><span [innerHTML]="'confirmation.onwStep3' | translate"></span></li>
      <li id="onw-steps-item4"><span [innerHTML]="'confirmation.onwStep4' | translate"></span></li>
      <li id="onw-steps-item5"><span [innerHTML]="'confirmation.onwStep5' | translate: employmentCounsellorLinkParam"></span></li>
      <li id="onw-steps-item6"><span [innerHTML]="'confirmation.onwStep6' | translate"></span></li>
    </ul>
  </div>

  <div *ngIf="applicationProgramType === ProgramType.ODS">
    <div id="ods-title" class="ontario-margin-top-40-! ontario-padding-bottom-8-!" ><span [innerHTML]="'confirmation.odsNextSteps' | translate"></span></div>
    <ul id="ods-steps" class="ontario-margin-left-32-!" type="1">
      <li id="ods-steps-item1"><span [innerHTML]="'confirmation.odsStep1' | translate"></span></li>
      <li id="ods-steps-item2"><span [innerHTML]="'confirmation.odsStep2' | translate"></span></li>
      <li id="ods-steps-item3"><span [innerHTML]="'confirmation.odsStep3' | translate"></span></li>
      <li id="ods-steps-item4"><span [innerHTML]="'confirmation.odsStep4' | translate: odspAdjudicationExceptionsLinkParam"></span></li>
      <li id="ods-steps-item5"><span [innerHTML]="'confirmation.odsStep5' | translate"></span></li>
      <li id="ods-steps-item6"><span [innerHTML]="'confirmation.odsStep6' | translate"></span></li>
      <li id="ods-steps-item7"><span [innerHTML]="'confirmation.odsStep7' | translate"></span></li>
    </ul>
  </div>

  <div *ngIf="applicationProgramType === ProgramType.ONWODS">
    <div id="onwods-title" class="ontario-margin-top-40-! ontario-padding-bottom-8-!" ><span [innerHTML]="'confirmation.onwodsNextSteps' | translate"></span></div>
    <ul id="onwods-steps" class="ontario-margin-left-32-!" type="1">
      <li id="onwods-steps-item1"><span [innerHTML]="'confirmation.onwodsStep1' | translate"></span></li>
      <li id="onwods-steps-item2"><span [innerHTML]="'confirmation.onwodsStep2' | translate"></span></li>
      <li id="onwods-steps-item3"><span [innerHTML]="'confirmation.onwodsStep3' | translate"></span></li>
      <li id="onwods-steps-item4"><span [innerHTML]="'confirmation.onwodsStep4' | translate"></span></li>
      <li id="onwods-steps-item5"><span [innerHTML]="'confirmation.onwodsStep5' | translate: odspAdjudicationExceptionsLinkParam"></span></li>
      <li id="onwods-steps-item6"><span [innerHTML]="'confirmation.onwodsStep6' | translate"></span></li>
      <li id="onwods-steps-item7"><span [innerHTML]="'confirmation.onwodsStep7' | translate"></span></li>
      <li id="onwods-steps-item8"><span [innerHTML]="'confirmation.onwodsStep8' | translate"></span></li>
      <li id="onwods-steps-item9"><span [innerHTML]="'confirmation.onwodsStep9' | translate"></span></li>
    </ul>
  </div>


  <div class="clear-cash-hintText">
    <sd-hint-text [id]="'confirmation.clear.cache.hint'"
                  [text]="'confirmation.clear.cache.step.hintText'"
                  [expandableContent]="'confirmation.clear.cache.step.hintContent'"
                  [expandableContentParam]="clearCacheLinkParam">
    </sd-hint-text>
  </div>

  <div *ngIf="applicationProgramType === ProgramType.ONWODS" class="looking-for-job ontario-margin-top-40-!" >
    <div id="look-for-job-title" class="ontario-h5">{{ 'confirmation.lookingForJobTitle' | translate}}</div>
    <div id="look-for-job-container">
      <span [innerHTML]="'confirmation.lookingForJobDescription' | translate: employmentCounsellorLinkParam"></span>
    </div>
  </div>

  <div id="myB-title" class="ontario-h5 ontario-margin-top-48-!">{{'confirmation.my.benefit.title' | translate}}</div>
  <p class="ontario-margin-bottom-32-!" [innerHTML]="'confirmation.my.benefit.content' | translate:{link: myBenefitsLink}"></p>

  <div id="contact-information-title" class="ontario-h5 alert-nomaxwidth ontario-margin-top-48-!">{{'confirmation.question.about.status' | translate}}<a href="{{appStatusCheckerUrl}}?referenceCode={{referenceCode}}" id='asc-Link' target="_blank">{{'confirmation.application.status.checker' | translate}}</a>{{'confirmation.contact.us' | translate}}</div>
  <div id="contact-information-container" class="ontario-margin-top-40-!">
    <svg class="ontario-icon contact-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
      <use href="#ontario-icon-phone"></use>
    </svg>
    <span>{{ 'confirmation.contactPhoneNumber' | translate}}</span><a href="tel:18889991142" target="_blank">{{ 'confirmation.contactPhoneNumber.number' | translate}}</a>
    <div class="contact-info">
      <div>{{ 'confirmation.contactCallCentres' | translate}}</div>
      <div>{{ 'confirmation.contactOperatingDays' | translate}}</div>
      <div>{{ 'confirmation.contactOperatingHours' | translate}}</div>
    </div>
    <svg class="ontario-icon contact-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
      <use href="#ontario-icon-tty"></use>
    </svg>
    <span>{{ 'confirmation.contactTTY' | translate}}</span><a href="tel:18003875559" target="_blank">{{ 'confirmation.contactTTY.number' | translate}}</a>
  </div>

  <div class="sharing-feedback ontario-margin-top-40-!" >
    <div id="sharing-feedback-title" class="ontario-h5">{{ 'confirmation.sharingFeedbackTitle' | translate}}</div>
    <div id="sharing-feedback-container">
      <div>
        <a href='{{feedbackUrl}}' target='_blank'>
          <span>{{ 'confirmation.feedbackDescription1' | translate}}</span>
        </a>
        <span>{{ 'confirmation.feedbackDescription2' | translate}}</span>
      </div>
    </div>
  </div>

  <div class="ontario-padding-top-40-! ontario-padding-left-8-! button-container">
    <app-mccss-submit-button buttonId="home-button" dataE2E="homeBtn" buttonLabel="{{isIBauUser ? 'confirmation.startNewApplication' : 'confirmation.backToHomePageButton'}}" [preventDoubleClick]="true" (handleClick)="onSubmit()"></app-mccss-submit-button>
    <app-mccss-submit-button dataE2E="skipBtn" buttonLabel="confirmation.my.benefit.button" [secondaryButton]="true" [preventDoubleClick]="true" (handleClick)="handleMyBenefitBtn()"></app-mccss-submit-button>
  </div>
</sd-page-container>
