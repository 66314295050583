<sd-page-container [pageId]="pageId"
                   [showBackButton]=true
                   [showPsRedirectError]="showPsRedirectError"
                   [showResumeBanner]="showResumeBanner"
                   [showRequiredInfoBanner]="showRequiredInfoBanner">
  <div class="ontario-label">{{ 'bank-details.page.info' | translate}}</div>
  <form [formGroup]="form" sdDisableAutocomplete>
    <div class="form-group required">
      <sd-radio-question [showError]="showError" [id]="'bank-details.provideBankDetails'"
                         controlName="provideBankDetails" [required]="true" [context]="'bank-details.provideBankDetails.context'">
      </sd-radio-question>
      <div *ngIf="provideBankDetails === 'yes'">
        <img class="cheque-image" id="cheque-image" alt="{{'bank-details.checkImage.alt' | translate}}" tabindex="0" src="assets/images/cheque.svg"/>
        <sd-text-question [showError]="showError" [id]="'bank-details.bankName'" [required]="true"
                          [minLength]=1 [maxLength]=60
                          [validationFns]="bankNameValidationFns"
                          [hintText]="'bank-details.bankName.hintText'"
                          [isAutoComplete]="true"
                          [autoCompleteChoices]="choices"
                          [capitalizeFirstLetter]="true"
                          controlName="bankName">
        </sd-text-question>
        <sd-text-question [showError]="showError" [id]="'bank-details.accountValue'" [required]="true" [isDollarValue]="true"
                          [minLength]=1 [validationFns]="accountValueValidationFns"
                          [validationRegex]="accountValuePattern" [validationRegexError]="'error.invalid.currency.amount'"
                          [characterWidth]= 10
                          controlName="accountValue">
        </sd-text-question>
        <sd-radio-question [showError]="showError" [id]="'bank-details.accountType'"
                           controlName="accountType" [required]="true" [choices]="accountTypes">
        </sd-radio-question>
        <sd-text-question [showError]="showError" [id]="'bank-details.branchNumber'" [required]="true"
                          [validationFns]="branchNumberValidationFns"
                          [asyncValidationFns]="branchNumberAsyncValidationFns"
                          (blurEvent)="onBranchNumberChange($event)"
                          [characterWidth]= 5
                          [showCustomError]="invalidBankDetailError"
                          [hintText]="'bank-details.branchNumber.hintText'"
                          [hintExpandableContent]="'bank-details.branchNumber.hintContent'"
                          controlName="branchNumber">
        </sd-text-question>
        <sd-text-question [showError]="showError" [id]="'bank-details.institutionNumber'" [required]="true"
                          [validationFns]="institutionNumberValidationFns"
                          [asyncValidationFns]="institutionNumberAsyncValidationFns"
                          [characterWidth]= 3
                          [hintText]="'bank-details.institutionNumber.hintText'"
                          [hintExpandableContent]="'bank-details.institutionNumber.hintContent'"
                          [showCustomError]="invalidBankDetailError"
                          controlName="institutionNumber">
        </sd-text-question>
        <sd-text-question [showError]="showError" [id]="'bank-details.accountNumber'" [required]="true"
                          [validationFns]="accountNumberValidationFns"
                          [customErrorParam]="accountNumberParam"
                          [characterWidth]= 10
                          [hintText]="'bank-details.accountNumber.hintText'"
                          [hintExpandableContent]="'bank-details.accountNumber.hintContent'"
                          controlName="accountNumber">
        </sd-text-question>
      </div>
    </div>
    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
  </form>
</sd-page-container>
