import {Choice} from '../../common/utils/questions/question-choices';

export function bankNameChoices() {
  const bankNames: Choice[] = [
    {value: 'RBC - Royal Bank of Canada', label:'bank-details.names.rbc', lang: 'en', relatedValue: 7},
    {value: 'RBC -  La Banque Royale du Canada', label:'bank-details.names.rbc', lang: 'fr', relatedValue: 7},
    {value: 'TD - Toronto-Dominion Bank', label:'bank-details.names.td', lang: 'en', relatedValue: 7},
    {value: 'TD - La Banque Toronto-Dominion', label:'bank-details.names.td', lang: 'fr', relatedValue: 7},
    {value: 'BMO - Bank of Montreal', label:'bank-details.names.bmo', lang: 'en', relatedValue: 7},
    {value: 'BMO - Banque de Montréal', label:'bank-details.names.bmo', lang: 'fr', relatedValue: 7},
    {value: 'Scotiabank - Bank of Nova Scotia', label:'bank-details.names.scotia', lang: 'en', relatedValue: 7},
    {value: 'Banque Scotia - La Banque de Nouvelle-Écosse', label:'bank-details.names.scotia', lang: 'fr', relatedValue: 7},
    {value: 'CIBC - Canadian Imperial Bank of Commerce', label:'bank-details.names.cibc', lang: 'en', relatedValue: 7},
    {value: 'CIBC - La Banque Canadienne Impériale do Commerce', label:'bank-details.names.cibc', lang: 'fr', relatedValue: 7},
    {value: 'National Bank', label:'bank-details.names.national', lang: 'en', relatedValue: 7},
    {value: 'La Banque Nationale', label:'bank-details.names.national', lang: 'fr', relatedValue: 7},
    {value: 'Simplii Financial', label:'bank-details.names.simplii', relatedValue: 10},
    {value: 'Tangerine Bank', label:'bank-details.names.tangerine', lang: 'en', relatedValue: 10},
    {value: 'La Banque Tangerine', label:'bank-details.names.tangerine', lang: 'fr', relatedValue: 10},
    {value: 'FirstOntario Credit Union', label:'bank-details.names.firstontario', relatedValue: 12},
    {value: 'Meridian Credit Union', label:'bank-details.names.meridian', relatedValue: 12},
  ];
  return bankNames
}
