import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {LoadScriptService} from './load-script.service';

@Injectable({
  providedIn: 'root'
})
export class LoadScriptServiceImpl implements LoadScriptService {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  loadScript(src): void {
    const script = document.createElement('script');
    script.src = src;
    this.document.body.appendChild(script);
  }
}
