import {ProgramType} from '../../../models/program-type';
import {RelationshipType} from '../../../models/relationship-type';

export const deliveryTypeChoices = new Map<string, string>([
  ['Standard street address', 'address-information.choices.address.type.standard.street.address'],
  ['Rural route', 'address-information.choices.address.type.rural.route'],
  ['PO Box', 'address-information.choices.address.type.po.box'],
  ['General delivery', 'address-information.choices.address.type.general.delivery']
]);

export const streetTypeChoices = new Map<string, string>([
  ['Street', 'address-information.choices.street.type.street']
]);

export const directionChoices = new Map<string, string>([
  ['East', 'address-information.choices.direction.east'],
  ['North', 'address-information.choices.direction.north'],
  ['Northeast', 'address-information.choices.direction.northeast'],
  ['Northwest', 'address-information.choices.direction.northwest'],
  ['South', 'address-information.choices.direction.south'],
  ['Southeast', 'address-information.choices.direction.southeast'],
  ['Southwest', 'address-information.choices.direction.southwest'],
  ['West', 'address-information.choices.direction.west']
]);

export const provinceChoices = new Map<string, string>([
  ['Ontario', 'choices.province.ontario'],
  ['Alberta', 'choices.province.alberta'],
  ['British Columbia', 'choices.province.britishcolumbia'],
  ['Manitoba', 'choices.province.manitoba'],
  ['New Brunswick', 'choices.province.newbrunswick'],
  ['Newfoundland and Labrador', 'choices.province.newfoundlandlabrador'],
  ['Northwest Territories', 'choices.province.northwestterritories'],
  ['Nova Scotia', 'choices.province.novascotia'],
  ['Nunavut', 'choices.province.nunavut'],
  ['Prince Edward Island', 'choices.province.princeedwardisland'],
  ['Quebec', 'choices.province.quebec'],
  ['Saskatchewan', 'choices.province.saskatchewan'],
  ['Yukon', 'choices.province.yukon']
]);

export const maritalStatusChoices = new Map<string, string>([
  ['Single', 'choices.maritalStatus.single'],
  ['Divorced', 'choices.maritalStatus.divorced'],
  ['Separated', 'choices.maritalStatus.separated'],
  ['Common Law', 'choices.maritalStatus.commonLaw'],
  ['Married', 'choices.maritalStatus.married'],
  ['Widowed', 'choices.maritalStatus.widowed']
]);

export const institutionChoices = new Map<string, string>([
  ['INSTITUTION_CAMH','additional-information.livingInAnInstitutionPanel.camh'],
  ['INSTITUTION_HOMEWOOD_HEALTH','additional-information.livingInAnInstitutionPanel.homewoodHealthCentre'],
  ['INSTITUTION_HOSPITAL', 'additional-information.livingInAnInstitutionPanel.hospitalOrMHCentre'],
  ['INSTITUTION_LONG_TERM_CARE', 'additional-information.livingInAnInstitutionPanel.longTermCare'],
  ['INSTITUTION_SUBSTANCE_ABUSE_PROGRAM', 'additional-information.livingInAnInstitutionPanel.substanceAbuse'],
  ['INSTITUTION_WOMENS_SHELTER', 'additional-information.livingInAnInstitutionPanel.womensShelter'],
  ['INSTITUTION_SPECIAL_CARE', 'additional-information.livingInAnInstitutionPanel.specialCare'],
  ['INSTITUTION_GROUP_RESIDENCE', 'additional-information.livingInAnInstitutionPanel.groupResidence'],
  ['INSTITUTION_SUPPORTED_RESIDENCE', 'additional-information.livingInAnInstitutionPanel.supportedResidence'],
  ['INSTITUTION_SCHOOL_HEARING_IMPAIRED', 'additional-information.livingInAnInstitutionPanel.hearingImpaired'],
  ['INSTITUTION_SCHOOL_VISION_IMPAIRED', 'additional-information.livingInAnInstitutionPanel.visionImpaired'],
  ['INSTITUTION_SCHOOL_FAMILY_SERVICES', 'additional-information.livingInAnInstitutionPanel.familyServices']
]);

export const stayPartTimeOrFullTimeChoices = new Map<string, string>([
  ['full-time', 'choices.radioDefault.fullTime'],
  ['part-time', 'choices.radioDefault.partTime']
]);

export const developmentServicesOntarioChoices = new Map<string, string>([
  ['approved', 'choices.radioDefault.approved'],
  ['applied', 'choices.radioDefault.applied'],
  ['no', 'choices.radioDefault.no']
]);

export const visualCheckboxChoices = new Map<string, string>([
  ['yes', 'additional-information.multipleApplicants.visualSupport']
]);

export const NeedTrustee = new Map<string, string> ([
  ['trusteeIdentified', 'choices.need.trustee.yes.identified'],
  ['trusteeNotIdentified', 'choices.need.trustee.yes.notIdentified'],
  ['no', 'choices.need.trustee.no'],
]);

export const NeedOrganizationTrustee = new Map<string, string> ([
  ['trusteeIdentified', 'choices.radioDefault.yes'],
  ['no', 'choices.radioDefault.no']
]);

export const hearingCheckboxChoices = new Map<string, string>([
  ['yes', 'additional-information.multipleApplicants.hearingSupport']
]);

export const verbalCheckboxChoices = new Map<string, string>([
  ['yes', 'additional-information.multipleApplicants.verbalSupport']
]);

export const communicationCheckboxChoices = new Map<string, string>([
  ['yes', 'additional-information.multipleApplicants.communicationSupport']
]);

export const mobilityCheckboxChoices = new Map<string, string>([
  ['yes', 'additional-information.multipleApplicants.mobilitySupport']
]);

export const pregnantCheckboxChoices = new Map<string, string>([
  ['yes', 'additional-information.pregnant']
]);

export const schoolTypeChoices = new Map<string, string>([
  ['secondarySchool', 'additional-information.secondarySchool'],
  ['postSecondarySchool', 'additional-information.postSecondarySchool']
]);

export const housingSituationChoices = new Map<string, string>([
  ['I pay rent, my meals are not included', 'housing-situation.choices.currentHousingSituation.iPayRentExcludingMeals'],
  ['I pay rent that includes my meals', 'housing-situation.choices.currentHousingSituation.iPayRentIncludingMeals'],
  ['I pay subsidized rent', 'housing-situation.choices.currentHousingSituation.iPaySubsidizedRent'],
  ['I own my home', 'housing-situation.choices.currentHousingSituation.iOwnMyHome'],
  ['I am staying in an emergency hostel or shelter', 'housing-situation.choices.currentHousingSituation.iStayInEmergencyHostelOrShelter'],
  ['i do not pay rent with meals provided', 'housing-situation.review.currentHousingSituation.iDoNotPayRentWithMeals'],
  ['i do not pay rent without meals provided', 'housing-situation.review.currentHousingSituation.iDoNotPayRentWithoutMeals'],
  ['I have no housing costs', 'housing-situation.choices.currentHousingSituation.noHouseCost'],
]);

export const housingCostChoices = new Map<string, string>([
  ['Heat', 'housing-situation.otherHousingCostHeat.label.checkbox'],
  ['Hydro', 'housing-situation.otherHousingCostHydro.label.checkbox'],
  ['Utilities', 'housing-situation.otherHousingCostUtilities.label.checkbox'],
  ['Condo fees', 'housing-situation.otherHousingCostCondoFee.label.checkbox'],
  ['Home or tenant insurance', 'housing-situation.otherHousingCostHomeOrTenantInsurance.label.checkbox'],
  ['Property tax', 'housing-situation.otherHousingCostPropertyTax.label.checkbox']
]);

export const livingWithYouRelationshipChoices = new Map<string, string>([
  ['Parent', 'housing-situation.choices.livingWithYouRelationship.parent'],
  ['Roommate', 'housing-situation.choices.livingWithYouRelationship.roomMate'],
  ['Landlord', 'housing-situation.choices.livingWithYouRelationship.landlord'],
  ['Relative', 'housing-situation.choices.livingWithYouRelationship.relative'],
  ['Tenant', 'housing-situation.choices.livingWithYouRelationship.tenant'],
  ['Other', 'housing-situation.choices.livingWithYouRelationship.other']
]);

export const incomeChoices = new Map<string, string>([
  ['Trying to get this income', 'household-income.choices.radio.tryingToGetThisIncome'],
  ['Receiving this income', 'household-income.choices.radio.receivingThisIncome']
]);

export const vehicleMakeChoices = new Map<string, string>([
  ['Ford', 'financial-assets.vehicle.make.option.ford'],
  ['Dodge', 'financial-assets.vehicle.make.option.dodge'],
  ['Honda', 'financial-assets.vehicle.make.option.honda'],
  ['Toyota', 'financial-assets.vehicle.make.option.toyota'],
  ['Hyundai', 'financial-assets.vehicle.make.option.hyundai'],
  ['Mazda', 'financial-assets.vehicle.make.option.mazda'],
  ['Nissan', 'financial-assets.vehicle.make.option.nissan'],
  ['Chrysler', 'financial-assets.vehicle.make.option.chrysler'],
  ['Kia', 'financial-assets.vehicle.make.option.kia'],
  ['Volkswagen', 'financial-assets.vehicle.make.option.volkswagen'],
  ['Acura', 'financial-assets.vehicle.make.option.acura'],
  ['Alfa', 'financial-assets.vehicle.make.option.alfa'],
  ['AM General', 'financial-assets.vehicle.make.option.amgeneral'],
  ['AMC', 'financial-assets.vehicle.make.option.amc'],
  ['Audi', 'financial-assets.vehicle.make.option.audi'],
  ['Bertone', 'financial-assets.vehicle.make.option.bertone'],
  ['BMW', 'financial-assets.vehicle.make.option.bmw'],
  ['Buick', 'financial-assets.vehicle.make.option.buick'],
  ['Cadillac', 'financial-assets.vehicle.make.option.cadillac'],
  ['Chevrolet', 'financial-assets.vehicle.make.option.chevrolet'],
  ['Daewoo', 'financial-assets.vehicle.make.option.daewoo'],
  ['Daihatsu', 'financial-assets.vehicle.make.option.daihutsu'],
  ['Datsun', 'financial-assets.vehicle.make.option.datsun'],
  ['Eagle', 'financial-assets.vehicle.make.option.eagle'],
  ['Fiat', 'financial-assets.vehicle.make.option.fiat'],
  ['Geo', 'financial-assets.vehicle.make.option.geo'],
  ['GMC', 'financial-assets.vehicle.make.option.gmc'],
  ['Harley Davidson', 'financial-assets.vehicle.make.option.harleydavidson'],
  ['Indian', 'financial-assets.vehicle.make.option.indian'],
  ['Infiniti', 'financial-assets.vehicle.make.option.infiniti'],
  ['International', 'financial-assets.vehicle.make.option.international'],
  ['Isuzu', 'financial-assets.vehicle.make.option.isuzu'],
  ['Jaguar', 'financial-assets.vehicle.make.option.jaguar'],
  ['Jeep', 'financial-assets.vehicle.make.option.jeep'],
  ['Kawasaki', 'financial-assets.vehicle.make.option.kawasaki'],
  ['Land Rover', 'financial-assets.vehicle.make.option.landrover'],
  ['Lexus', 'financial-assets.vehicle.make.option.lexus'],
  ['Lincoln', 'financial-assets.vehicle.make.option.lincoln'],
  ['Mercedes Benz', 'financial-assets.vehicle.make.option.mercedesbenz'],
  ['Mercury', 'financial-assets.vehicle.make.option.mercury'],
  ['Merkur', 'financial-assets.vehicle.make.option.merkur'],
  ['Mitsubishi', 'financial-assets.vehicle.make.option.mitsubishi'],
  ['Oldsmobile', 'financial-assets.vehicle.make.option.oldsmobile'],
  ['Peugeot', 'financial-assets.vehicle.make.option.peugeot'],
  ['Plymouth', 'financial-assets.vehicle.make.option.plymouth'],
  ['Pontiac', 'financial-assets.vehicle.make.option.pontiac'],
  ['Porsche', 'financial-assets.vehicle.make.option.porsche'],
  ['Renault', 'financial-assets.vehicle.make.option.renault'],
  ['Saab', 'financial-assets.vehicle.make.option.saab'],
  ['Saturn', 'financial-assets.vehicle.make.option.saturn'],
  ['Sterling', 'financial-assets.vehicle.make.option.sterling'],
  ['Subaru', 'financial-assets.vehicle.make.option.subaru'],
  ['Suzuki', 'financial-assets.vehicle.make.option.suzuki'],
  ['Volvo', 'financial-assets.vehicle.make.option.volvo'],
  ['Yamaha', 'financial-assets.vehicle.make.option.yamaha'],
  ['Yugo', 'financial-assets.vehicle.make.option.yugo'],
]);

export const householdOtherIncomeTypeChoices = new Map<string, string>([
  ['Child support', 'household-income.other.income.type.option.child-support'],
  ['HST return', 'household-income.other.income.type.option.hst-return'],
  ['Income tax return', 'household-income.other.income.type.option.tax-return'],
  ['Legal settlement or award', 'household-income.other.income.type.option.settlement-reward'],
  ['Pension - private', 'household-income.other.income.type.option.pension-private'],
  ['Pension - foreign', 'household-income.other.income.type.option.pension-foreign'],
  ['Rental income for a second property other than where you live', 'household-income.other.income.type.option.rental-income'],
  ['Spousal support', 'household-income.other.income.type.option.spousal-support'],
  ['RRSP or RRIF payments', 'household-income.other.income.type.option.rrsp-rrif'],
  ['War Veterans Allowance', 'household-income.other.income.type.option.war-veterans-allowance'],
  ['CPP disabled contributor\'s child\'s benefit', 'household-income.other.income.type.option.cpp-disabled-cb'],
  ['CPP surviving child\'s benefit', 'household-income.other.income.type.option.cpp-surviving-cb'],
  ['QPP death benefit', 'household-income.other.income.type.option.qpp-death-benefit'],
  ['QPP surviving spouse\'s pension', 'household-income.other.income.type.option.qpp-surviving-spouse-pension'],
  ['QPP retirement', 'household-income.other.income.type.option.qpp-retirement'],
  ['QPP orphan\'s pension', 'household-income.other.income.type.option.qpp-orphan-pension'],
  ['QPP disability', 'household-income.other.income.type.option.qpp-disability'],
  ['Ontario Child Benefit', 'household-income.other.income.type.option.ontario-child-ben'],
  ['Canada Child Benefit', 'household-income.other.income.type.option.canada-child-ben'],
  ['Resettlement Assistance Program (RAP)', 'household-income.other.income.type.option.resettlement-assist-program'],
  ['My income type is not listed here', 'household-income.other.income.type.option.not-listed-here'],
]);

export const otherAssetTypeChoices = new Map<string, string>([
  ['Educational Savings Plan - RESP', 'financial-assets.other.asset.type.option.resp'],
  ['Registered Disability Savings Plan - RDSP', 'financial-assets.other.asset.type.option.rdsp'],
  ['Second Property - not the home you live in', 'financial-assets.other.asset.type.option.second.property'],
  ['Funeral or burial plan', 'financial-assets.other.asset.type.option.funeral.plan'],
  ['Life insurance - Term', 'financial-assets.other.asset.type.option.insurance.term'],
  ['Life insurance - Whole life', 'financial-assets.other.asset.type.option.insurance.whole'],
  ['Business or farm assets or inventory', 'financial-assets.other.asset.type.option.business'],
  ['Money from an inheritance', 'financial-assets.other.asset.type.option.inheritance'],
  ['Stocks or shares', 'financial-assets.other.asset.type.option.stocks'],
  ['Valuables', 'financial-assets.other.asset.type.option.valuables'],
  ['My asset type is not listed here', 'financial-assets.other.asset.type.option.not.listed'],
]);

export const bankNameChoices = new Map<string, string>([
  ['RBC - Royal Bank of Canada', 'bank-details.names.rbc'],
  ['RBC -  La Banque Royale du Canada', 'bank-details.names.rbc'],
  ['TD - Toronto-Dominion Bank', 'bank-details.names.td'],
  ['TD - La Banque Toronto-Dominion', 'bank-details.names.td'],
  ['BMO - Bank of Montreal', 'bank-details.names.bmo'],
  ['BMO - Banque de Montréal', 'bank-details.names.bmo'],
  ['Scotiabank - Bank of Nova Scotia', 'bank-details.names.scotia'],
  ['Banque Scotia - La Banque de Nouvelle-Écosse', 'bank-details.names.scotia'],
  ['CIBC - Canadian Imperial Bank of Commerce', 'bank-details.names.cibc'],
  ['CIBC - La Banque Canadienne Impériale do Commerce', 'bank-details.names.cibc'],
  ['National Bank', 'bank-details.names.national'],
  ['La Banque Nationale', 'bank-details.names.national'],
  ['Simplii Financial', 'bank-details.names.simplii'],
  ['Tangerine Bank', 'bank-details.names.tangerine'],
  ['La Banque Tangerine', 'bank-details.names.tangerine'],
  ['la Banque Tangerine', 'bank-details.names.tangerine'], // It can be removed in version 25.2 when the first letter capitalization is applied to all bank name fields.
  ['FirstOntario Credit Union', 'bank-details.names.firstontario'],
  ['Meridian Credit Union', 'bank-details.names.meridian'],
]);

export const bankAccountTypeChoices = new Map<string, string>([
  ['chequing', 'bank-details.account.choices.chequing'],
  ['savings', 'bank-details.account.choices.savings']
]);

export const radioDefault = new Map<string, string>([
  ['yes', 'choices.radioDefault.yes'],
  ['no', 'choices.radioDefault.no']
]);

export const radioRevertedDefault = new Map<string, string>([
  ['no', 'choices.radioDefault.yes'],
  ['yes', 'choices.radioDefault.no']
]);

export const ReceivedSocialAssistanceProgram = new Map<string, string>([
  [ProgramType.ODS, 'choices.radioDefault.odsp'],
  [ProgramType.ONW, 'choices.radioDefault.ow'],
  [ProgramType.ACSD, 'choices.radioDefault.acsd'],
]);

export const radioChildCareType = new Map<string, string>([
  ['beforeAfterSchoolProgram', 'earned-income.child.care.income.program.type.school.program'],
  ['licensedChildCare', 'earned-income.child.care.income.program.type.licensed.program'],
  ['unlicensedChildCare', 'earned-income.child.care.income.program.type.unlicensed.program']
]);

export const sexAtBirth = new Map<string, string>([
  ['male', 'choices.genderAtBirth.male'],
  ['female', 'choices.genderAtBirth.female']
]);

export const language = new Map<string, string>([
  ['english', 'choices.language.english'],
  ['french', 'choices.language.french']
]);

export const applyingFor = new Map<string, string>([
  ['APPLICATION_SELF', 'choices.applyingForSomeoneElse.myself'],
  ['APPLICATION_OTHER', 'choices.applyingForSomeoneElse.other']
]);

export const relationship = new Map<string, string>([
  [RelationshipType.RELATIONSHIP_TRUSTEE, 'choices.applyingForSomeoneElseRelationship.trustee'],
  [RelationshipType.RELATIONSHIP_ORGANIZATION_TRUSTEE, 'choices.applyingForSomeoneElseRelationship.organizationTrustee'],
  [RelationshipType.RELATIONSHIP_OTHER, 'choices.applyingForSomeoneElseRelationship.other'],
  [RelationshipType.APPLICATION_SUPPORT, 'choices.applyingForSomeoneElseRelationship.support'],
  [RelationshipType.LOCAL_OFFICE_ASSISTANCE, 'choices.applyingForSomeoneElseRelationship.localOfficeAssistance']
]);

export const statusInCanadaChoices = new Map<string, string>([
  ['Canadian citizen born in Canada', 'choices.statusInCanada.canadian.born.canada'],
  ['Status Indian', 'choices.statusInCanada.status.indian'],
  ['Canadian citizen not born in Canada', 'choices.statusInCanada.canadian.not.born.canada'],
  ['Permanent resident', 'choices.statusInCanada.permanent.resident'],
  ['Applicant for permanent residence', 'choices.statusInCanada.applicant.for.residence'],
  ['Convention refugee', 'choices.statusInCanada.convention.refugee'],
  ['Refugee claimant', 'choices.statusInCanada.refugee.claimant'],
  ['Government assisted refugee', 'choices.statusInCanada.refugee.government.assisted.claimant'],
  ['Deportee', 'choices.statusInCanada.deportee'],
  ['Temporary resident permit', 'choices.statusInCanada.temporary.resident.permit'],
  ['Visitor or tourist', 'choices.statusInCanada.visitor.or.tourist'],
  ['Student visa', 'choices.statusInCanada.student.visa'],
]);

export const radioEarnedIncomeType = new Map<string, string>([
  ['fullTime', 'earned-income.income.type.choices.full.time'],
  ['partTime', 'earned-income.income.type.choices.part.time'],
  ['selfEmployed', 'earned-income.income.type.choices.self.employment'],
  ['training', 'earned-income.income.type.choices.training']
]);
