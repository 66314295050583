import {DOCUMENT} from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {concatMap, filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';

import {CaseWorkerAuthorizeService} from '../services/caseWorkerAuthorizeService';
import {WindowService} from '../services/window.service';
import {PageInfo} from '../models/page-map';
import {AuthService} from '../services/auth.service';
import {IntakeService} from '../services/intake.service';
import {LoadingSpinnerService} from '../services/loading-spinner.service';
import {ExternalRouter} from '../external.router';
import {MenuService} from '../services/menu.service';
import {ConfigService} from '../services/config.service';
import {RouteStateService} from 'src/app/services/route-state.service';
import {popup} from '../dialog-constants';
import {CreateAccountReminderParameter} from '../common/ui/create-account-reminder-dialog/create-account-reminder-parameter';
import {CreateAccountReminderDialogComponent} from '../common/ui/create-account-reminder-dialog/create-account-reminder-dialog.component';
import {UrlInfo} from '../models/url-map';

@Component({
  selector: 'app-mccss-header',
  templateUrl: './mccss-header.component.html',
  styleUrls: ['./mccss-header.component.scss']
})
export class MccssHeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
  private readonly _destroying$ = new Subject<void>()
  private readonly LANG = 'lang';
  @ViewChild('menuButton') menuButton!: ElementRef<HTMLInputElement>;
  caseWorkerDisplayName: string;
  publicSecureUserName: string;
  currentLang: string;
  previousRoute: string;
  displayMenu: boolean;
  displayMenuPages: Array<string>;
  menuType: string;
  currentPageId: string;
  subscriptions$: Subscription[] = [];
  mybLink: string
  appStatusCheckerLink: string
  applyForOdspLink: string
  envName: string
  dialogVisible = false;
  termAndConditionItems: any[];
  ontarioWorksLink: string
  isErrorOrInfoPage = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private translate: TranslateService,
    private authorizeService: CaseWorkerAuthorizeService,
    private authService: AuthService,
    private windowService: WindowService,
    private intakeService: IntakeService,
    private loadingSpinnerService: LoadingSpinnerService,
    private externalRouter: ExternalRouter,
    private menuService: MenuService,
    private configService: ConfigService,
    private routeStateService: RouteStateService,
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog
  ) {
    this.displayMenu = false;
    this.displayMenuPages = [ PageInfo.home, PageInfo.spouseInfo, PageInfo.childrenInfo, PageInfo.additionalInfo,
      PageInfo.sponsorship, PageInfo.confirmAddress, PageInfo.housingSituation, PageInfo.financialIndependence, PageInfo.earnedIncome,
      PageInfo.householdIncome, PageInfo.financialAssets, PageInfo.bankDetails, PageInfo.review,
      PageInfo.programRecommendation, PageInfo.inprogressOrExpiredApplication,
      PageInfo.loginConfirmation];
    this.envName = this.configService.getClientConfig()?.envName;
    this.ontarioWorksLink = this.configService.getUrl(this.translate.currentLang, UrlInfo.ontarioWorks);
    popup.absoluteTimeout.link = this.ontarioWorksLink;
  }

  ngOnInit(): void {
    this.currentLang = this.windowService.getLocalStorage(this.LANG) || this.windowService.getLanguage();
    this.setLanguage();  // Set the language in the HTML tag.
    this.translate.use(this.currentLang);
    this.populateTermAndConditionItems();
    this.setMenuLinkForLandingPage()

    // caseworker username display
    this.authorizeService.getDisplayName()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe(
        name => this.caseWorkerDisplayName = name
      )

    // public secure authenticated username display
    this.authService.getUserName()
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe(
        userName => this.publicSecureUserName = userName
      )
    // Customization for unauthorized page
    this.routeStateService.isErrorOrInfoPage$
      .pipe(takeUntil(this._destroying$))
      .subscribe(isErrorOrInfoPage => {
        this.isErrorOrInfoPage = isErrorOrInfoPage;
    });
    this.handleRouterNav();
  }

  private handleRouterNav(): void {
    // Listen to router navigation event to update page status for header menu
    this.router.events?.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.updateHeaderOnLocationChange(event.url);
      if (this.displayMenu && this.menuIsOpen()) {
        this.closeMenu();
      }
    });
  }
  // Checks if the menu is opened after routed to target page
  menuIsOpen(): boolean {
    const menuElement = document.querySelector('.ontario-navigation--open');
    return !!menuElement;
  }
  closeMenu(): void {
    document.getElementById('ontario-header-menu-toggler').click()
  }

  private setMenuLinkForLandingPage(): void {
    this.appStatusCheckerLink = this.configService.getUrl(this.currentLang, UrlInfo.applicationStatus);
    this.applyForOdspLink = this.configService.getUrl(this.currentLang, UrlInfo.applyForOdsp);
    this.mybLink = this.configService.getUrl(this.currentLang, UrlInfo.myBenefitsLogin);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  changeLanguage() {
    this.currentLang = this.currentLang === 'fr' ? 'en' : 'fr';
    this.translate.use(this.currentLang);
    this.populateTermAndConditionItems();
    this.windowService.setLocalStorage(this.LANG, this.currentLang);
    this.setMenuLinkForLandingPage();
    this.setLanguage();  // Update the language in the HTML tag.

    const regex = /inprogress-or-expired-application.*$/;
    if (!this.router.url.endsWith('additional-information') && !regex.test(this.router.url)) {
      this.router.titleStrategy.updateTitle(this.router.routerState.snapshot);
    }
    // refresh review page/consent component to ensure all fields are translated
    if (this.refreshPageOnLanguageSwitch()) {
      this.refreshComponent();
    }
    // blur focus on toggle button
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 300, { once: true })
  }

  refreshPageOnLanguageSwitch() {
    return !this.router.url.includes('address-review')
        && (this.router.url.includes('review') || this.router.url.includes('consent'))
  }

  setLanguage(): void {
    if (this.document?.documentElement) {
      this.document.documentElement.lang = this.currentLang;
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.subscriptions$.forEach(s => s.unsubscribe());
  }

  private refreshComponent() {
    let currentURL = this.router.url;
    const ind = currentURL.indexOf('?');
    if (ind !== -1) { // Exclude any params from URL for refresh
      currentURL = currentURL.substring(0,ind);
    }
    // Navigate to a lightweight page ('page not found' error page), then navigate back to current page
    // in order to refresh without prompting the user
    this.router.navigateByUrl('/error', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentURL]);
    });
  }

  private updateHeaderOnLocationChange(pageURL): void {
    this.menuService.setShowPSRedirectError(false); // reset error state
    this.displayMenu = false;

    // Keep menu off if applying for someone else
    if (this.intakeService.getIsApplyingForSomeoneElse()) {
      return;
    }

    pageURL = pageURL === '/' ? PageInfo.home : pageURL
    // Iterate through pages to see if menu should be displayed
    this.displayMenuPages.forEach(pageRoute => {
      const parts: string[] = pageURL.split('/');
      if (parts && !!parts.find(v => v.split('?')[0] === pageRoute)) {
        // Turn off menu for confirm address page
        if (pageRoute === PageInfo.addressInfo && this.previousRoute === pageRoute) {
          this.displayMenu = false;
        }
        else {
          this.displayMenu = true;
          this.currentPageId = pageRoute;

          // Determine the page type for page-specific menu content
          if (pageRoute === PageInfo.home) {
            this.menuType = 'landing';
          } else if (pageRoute === PageInfo.confirmation) {
            this.menuType = 'confirmation';
          } else if (pageRoute === PageInfo.inprogressOrExpiredApplication || pageRoute === PageInfo.loginConfirmation) {
            // On these pages, if the session is valid, and user is authorized to save, show only the logout option in the menu
            // Otherwise, hide the menu altogether
            this.menuType = 'onlyLogout';
            this.displayMenu = this.userAuthenticated();
          } else {
            this.menuType = 'sadaForm'; // Default to sadaForm i.e. personal-info, spouse-info, etc.
          }
        }
        this.previousRoute = pageRoute;
      }
    })

  }

  // go to before you apply page when user clicks the menu of Apply for Assistance on landing page
  applyForAssistance(e) {
    const applyForAssistantButton = document.querySelector('[id$="continue-button"]') as HTMLElement;
    applyForAssistantButton.click();
    e.preventDefault()
  }

  // Use the save and exit button attached to the form, since the logic is already handled there
  saveAndExit(e): void {
    this.menuService.setShowPSRedirectError(false); // reset error state
    const saveExitButton = document.querySelector('[id$="save-exit-button"]') as HTMLElement;
    saveExitButton.click();
    e.preventDefault()
  }

  // Directly create an account without the save and exit modal
  createAnAccount(e): void {
    this.menuService.setShowPSRedirectError(false); // reset error state
    this.intakeService.getApplication(this.currentPageId).subscribe(data => {
      const email = data?.jsonData?.email;

      this.dialogVisible = true
      const parameter: CreateAccountReminderParameter = {
        dialogName: 'menu-create-account', pageNameToSave: this.currentPageId,
        applicantEmail: email, noBodyContent: true, noReturnToApplicationLink: true, dialogMinWidth: true,
        termAndConditionItems: this.termAndConditionItems
      };
      const dialogRef = this.dialog.open(CreateAccountReminderDialogComponent, {panelClass: 'sada-dialog-class'});
      dialogRef.componentInstance.parameter = parameter;

      dialogRef.componentInstance.handleCreateAccount = () => {
        this.onCreateAccount(dialogRef, parameter);
      }
      dialogRef.componentInstance.handleContinueWithoutAccount = () => {
        dialogRef.close();
      }
      dialogRef.componentInstance.handleReturnToApplication = () => {
        dialogRef.close();
      }
      dialogRef.componentInstance.handleMockLogin = () => {
        this.onMockLogin(dialogRef, parameter);
      }
      dialogRef.componentInstance.handleClose = () => {
        dialogRef.close();
      }
      this.subscriptions$.push(dialogRef.afterClosed().subscribe(_ => {
        this.dialogVisible = false
      }))
    })
    e.preventDefault()
  }

  private onCreateAccount(dialogRef: any, parameter: CreateAccountReminderParameter): void {
    this.redirectToPublicSecure(parameter.applicantEmail);
    dialogRef.componentInstance.handleClose();
  }

  protected redirectToPublicSecure(applicantEmail: string): void {
    if (!applicantEmail) {
      this.menuService.setShowPSRedirectError(true);
      return;
    }

    this.menuService.setShowPSRedirectError(false);
    this.loadingSpinnerService.show(true);

    // Redirect to PSE and save progress, but do not save to intake since no form validation occurs
    // Resuming to the page will show empty fields unless previously saved
    const lang = this.translate.currentLang ? this.translate.currentLang : 'en';
    this.subscriptions$.push(this.authService.getPSRedirectURL(applicantEmail, lang)
      .pipe(switchMap((url) => {
          return this.intakeService.saveProgress(this.currentPageId)
            .pipe(switchMap(() => {
              this.loadingSpinnerService.show();
              return this.authService.logout()
                .pipe(map(() => url));
            }));
        })
      )
      .subscribe(url => {
        this.externalRouter.navigate(url, false);
      }, () => {
        this.loadingSpinnerService.hide()
        this.menuService.setShowPSRedirectError(true)
      }));
  }

  protected onMockLogin(dialogRef: any, parameter: CreateAccountReminderParameter): void {
    this.doMockLogin(parameter.pageNameToSave, parameter.applicantEmail);
    dialogRef.componentInstance.handleClose();
  }

  protected doMockLogin(pageNameToSave: string, applicantEmail: string) {
    this.subscriptions$.push(this.authService.mockLogin(applicantEmail).subscribe(url => {
      this.subscriptions$.push(this.intakeService.saveProgress(pageNameToSave).subscribe(() => {
        this.externalRouter.navigate(url.toString(), false);
      }, error => console.log('Error in mock login: ' + error?.message)))
    }, error => console.log('Error in mock login: ' + error?.message)));
  }

  /**
   * This method resets the auth-token from local-storage, the auth values in Auth-Service, invalidates the Spring Session, clears
   * both local-storage & session-storage, logs out all sessions from PSE, then redirect to Ontario Works page.
   */
  logout(e): void {
    const envName = this.configService.getClientConfig()?.envName;
    this.authService.psLogout(popup.absoluteTimeout.link)
      .pipe(
        concatMap(url => this.intakeService.logout().pipe(
            map(() => url)
          )
        ),
      ).subscribe((logoutUrl) => {
      localStorage.clear();
      sessionStorage.clear();
      this.externalRouter.navigate(logoutUrl, false);
    })
    e.preventDefault()
  }

  userAuthenticated(): boolean {
    return this.authService.isAuthorizedToSave();
  }

  showLogoutOption(): boolean {
    // Handle logic specific to these two pages
    if (this.router.url.includes(PageInfo.inprogressOrExpiredApplication)
      || this.router.url.includes(PageInfo.loginConfirmation)) {
      // Show menu and logout option if user is authenticated
      if (this.userAuthenticated()) {
        this.displayMenu = true;
        return true;
      }
      // Hide menu and logout option if user is not authenticated
      else {
        this.displayMenu = false;
      }
    }
    return false;
  }

  private populateTermAndConditionItems(): void {
    this.termAndConditionItems = [{value: 'yes', label: 'create-account.termAndCondition',
      labelParam: {linkUrl: this.configService.getUrl(this.translate.currentLang, UrlInfo.myBenefitsTerms)}, checked: false}]
  }
}
